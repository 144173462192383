export interface MarkdownRemark {
  html: string;
}

export interface CMSRichTextType {
  data: {
    childMarkdownRemark: MarkdownRemark;
  };
}

export interface CMSLinkType {
  text: string;
  url: string;
  type: 'url' | 'tel' | 'mail';
  order: number;
}

export interface CMSOpeningHoursType {
  dayRange: string;
  hours: string;
  order: number;
}

export function sortByOrder<T extends { order: number }>(a: T, b: T): number {
  return a.order - b.order;
}
