import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { type CMSLinkType, type CMSOpeningHoursType, type CMSRichTextType, sortByOrder } from '../model/CMS';
import { StaticImage } from '../views/Image';
import PageLayout from '../views/Layout/Page';
import T from '../views/Translate';

import styles from './contact.module.scss';

interface QueryData {
  strapiContact: {
    supportTitle: string;
    supportDescription: string;
    supportOpeningHours: CMSOpeningHoursType[];
    supportLinks: CMSLinkType[];
    assistanceTitle: string;
    assistanceDescription: CMSRichTextType;
    assistanceLinks: CMSLinkType[];
    accidenceTitle: string;
    accidenceDescription: CMSRichTextType;
    accidenceOpeningHours: CMSOpeningHoursType[];
    accidenceLinks: CMSLinkType[];
  };
}

export default function ContactPage(props: PageProps<QueryData>) {
  return (
    <PageLayout>
      <main className="main-container container container--top container--bottom">
        <T as="h1" className="mb-lg-5 mb-3" id="contact.title" />
        <div className={styles.sectionContainer}>
          <Section
            description={props.data.strapiContact.supportDescription}
            image="customer care"
            links={props.data.strapiContact.supportLinks}
            openingHours={props.data.strapiContact.supportOpeningHours}
            title={props.data.strapiContact.supportTitle}
          />
          <Section
            description={props.data.strapiContact.assistanceDescription}
            image="assistant service"
            links={props.data.strapiContact.assistanceLinks}
            title={props.data.strapiContact.assistanceTitle}
          />
          <Section
            description={props.data.strapiContact.accidenceDescription}
            image="insurance accident"
            links={props.data.strapiContact.accidenceLinks}
            openingHours={props.data.strapiContact.accidenceOpeningHours}
            title={props.data.strapiContact.accidenceTitle}
          />
        </div>
      </main>
    </PageLayout>
  );
}

interface SectionProps {
  image: string;
  title: string;
  description: string | CMSRichTextType;
  openingHours?: CMSOpeningHoursType[];
  links?: CMSLinkType[];
}

function Section(props: SectionProps) {
  let description = props.description;
  if (typeof description !== 'string') {
    description = description.data.childMarkdownRemark.html;
  }

  return (
    <section className={styles.section}>
      <div className={styles.title}>
        <StaticImage alt="" name={props.image} />
        <h4>{props.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <List className={styles.openingHours} items={props.openingHours}>
        {openingHours => (
          <div key={`${openingHours.dayRange}:${openingHours.hours}`}>
            {openingHours.dayRange}: {openingHours.hours}
          </div>
        )}
      </List>
      <List className={styles.links} items={props.links}>
        {link => {
          const props = {};
          if (link.type === 'url') {
            props['target'] = '_blank';
            props['rel'] = 'noreferrer noopener';
          }
          return (
            <a href={link.url} key={link.url} {...props}>
              {link.text}
            </a>
          );
        }}
      </List>
    </section>
  );
}

interface ListProps<T extends { order: number }> {
  className?: string;
  items: T[];

  children(item: T): React.ReactNode;
}

function List(props: ListProps<UnsafeAny>) {
  if (!props.items || !props.items.length) return null;
  return <div className={props.className}>{props.items.sort(sortByOrder).map(props.children)}</div>;
}

export const query = graphql`
  query ($language: String!) {
    strapiContact(locale: { eq: $language }) {
      supportTitle
      supportDescription
      supportOpeningHours {
        dayRange
        hours
        order
      }
      supportLinks {
        text
        url
        type
        order
      }
      assistanceTitle
      assistanceDescription {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      assistanceLinks {
        text
        url
        type
        order
      }
      accidenceTitle
      accidenceDescription {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      accidenceOpeningHours {
        dayRange
        hours
        order
      }
      accidenceLinks {
        text
        url
        type
        order
      }
    }
  }
`;
